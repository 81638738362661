
import { setStep } from "../slices/stepSlice"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { postData } from '../api/api'
import { setUser } from "../slices/userSlice"
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "./Loader";

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    console.log(window.location,'test')
    if(window.location.href.includes('/trade')) window.location.href = window.location.href.replace('/trade', '')
  },[])


  const onSubmit = data=>{
    setLoading(true)
    postData('user/login',data)
    .then(res=>{
      if(res.data.user){
        window.localStorage.setItem('token',res.data.token)
        // window.localStorage.setItem('user',JSON.stringfy(res.data.user))
        window.location.reload()
      }else{
        setLoading(false)
      }
    })
    .catch(err=>{
      setLoading(false)
      let e = err?.response?.data?.error ||'Unkown error, try again'
      toast.error(e)
    })
  }

  return (
    // <div style={{margin:'auto'}}> 
    <div style={{}}>
        {loading &&
          <Loader/>
        }
        <form className="Auth-form" onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder="Email"
                  className = {`${errors.email?'error':''}`}
                  {...register("email", { required: true})}
                />
                <input type="password" placeholder="Password"
                  className = {`${errors.password?'error':''}`}
                  {...register("password", { required: true})}
                />
           
            <button  className="btn btn-success" type="submit">Log in</button>
            <div className="Auth-extras">
                <span>Dont have an account? <a onClick={()=>dispatch(setStep({step:1}))} className="success">Signup here</a></span>
                <Link className="warning" style={{textDecolation:'none'}} to='/reset-password'>Forgot Password?</Link>
            </div>
        </form>
    </div>
  )
}

export default Login