export const banks = [
      {
        bank: "ABC Bank",
        code: [
            {
                branch: "Head Office/Koinange St.",
                code: "000"
            },
            {
                branch: "Westlands",
                code: "001"
            },
            {
                branch: "Industrial Area Branch",
                code: "002"
            },
            {
                branch: "Nkrumah Road Branch/mombasa",
                code: "003"
            },
            {
                branch: "Kisumu Branch",
                code: "004"
            },
            {
                branch: "Eldoret Branch",
                code: "005"
            },
            {
                branch: "Meru Branch",
                code: "006"
            },
            {
                branch: "Libra House",
                code: "007"
            },
            {
                branch: "Nakuru",
                code: "008"
            },
            {
                branch: "Lamu",
                code: "009"
            },
            {
                branch: "Thika Road Mall",
                code: "010"
            },
            {
                branch: "Green House Mall(GHM)",
                code: "011"
            }
        ]
    },
    {
        bank: "Bank of Africa",
        code: [
            {
                branch: "Reinsurance Plaza Nairobi",
                code: "000"
            },
            {
                branch: "Mombasa",
                code: "001"
            },
            {
                branch: "Westlands",
                code: "002"
            },
            {
                branch: "Uhuru Highway",
                code: "003"
            },
            {
                branch: "River Road",
                code: "004"
            },
            {
                branch: "Thika",
                code: "005"
            },
            {
                branch: "Kisumu",
                code: "006"
            },
            {
                branch: "Ruaraka",
                code: "007"
            },
            {
                branch: "Monrovia Street",
                code: "008"
            },
            {
                branch: "Nakuru",
                code: "009"
            },
            {
                branch: "Ngong Road",
                code: "010"
            },
            {
                branch: "Eldoret",
                code: "011"
            },
            {
                branch: "Embakasi",
                code: "012"
            },
            {
                branch: "Kericho",
                code: "013"
            },
            {
                branch: "Ongata Rongai",
                code: "014"
            },
            {
                branch: "Changamwe",
                code: "015"
            },
            {
                branch: "Bungoma",
                code: "016"
            },
            {
                branch: "KISII",
                code: "017"
            },
            {
                branch: "MERU",
                code: "018"
            },
            {
                branch: "Kitengela",
                code: "019"
            },
            {
                branch: "Nyali",
                code: "020"
            },
            {
                branch: "Galleria",
                code: "021"
            },
            {
                branch: "Greenspan Mall",
                code: "022"
            },
            {
                branch: "Upper Hilll",
                code: "023"
            },
            {
                branch: "Nanyuki",
                code: "024"
            },
            {
                branch: "Kenyatta Avenue",
                code: "026"
            },
            {
                branch: "Sameer Business Park",
                code: "027"
            },
            {
                branch: "Moi Avenue, Mombasa",
                code: "028"
            },
            {
                branch: "Ongata Rongai II",
                code: "029"
            },
            {
                branch: "Gikomba",
                code: "030"
            },
            {
                branch: "Githurai",
                code: "031"
            },
            {
                branch: "Embu",
                code: "032"
            },
            {
                branch: "Gateway Mall",
                code: "033"
            },
            {
                branch: "Kitale",
                code: "034"
            },
            {
                branch: "South B",
                code: "035"
            },
            {
                branch: "Digo Road",
                code: "036"
            },
            {
                branch: "Outering Road",
                code: "037"
            },
            {
                branch: "Likoni Road",
                code: "038"
            },
            {
                branch: "Thika Road",
                code: "039"
            },
            {
                branch: "Narok",
                code: "040"
            },
            {
                branch: "Gigiri",
                code: "042"
            },
            {
                branch: "Ukunda",
                code: "043"
            },
            {
                branch: "Kakamega",
                code: "044"
            }
        ]
    },
    {
        bank: "Barclays Bank",
        code: [
            {
                branch: "001",
                code: "Head Office – Vpc"
            },
            {
                branch: "002",
                code: "Kapsabet Branch"
            },
            {
                branch: "003",
                code: "Eldoret Branch"
            },
            {
                branch: "004",
                code: "Embu Branch"
            },
            {
                branch: "005",
                code: "Muranga Branch"
            },
            {
                branch: "006",
                code: "Kapenguria Branch"
            },
            {
                branch: "007",
                code: "Kericho Branch"
            },
            {
                branch: "008",
                code: "Kisii Branch"
            },
            {
                branch: "009",
                code: "Kisumu Branch"
            },
            {
                branch: "010",
                code: "South C Branch"
            },
            {
                branch: "011",
                code: "Limuru Branch"
            },
            {
                branch: "012",
                code: "Malindi Branch"
            },
            {
                branch: "013",
                code: "Meru Branch"
            },
            {
                branch: "014",
                code: "Eastleigh Branch"
            },
            {
                branch: "015",
                code: "Kitui Branch"
            },
            {
                branch: "016",
                code: "Nkrumah Road Branch"
            },
            {
                branch: "017",
                code: "Garissa Branch"
            },
            {
                branch: "018",
                code: "Nyamira Branch"
            },
            {
                branch: "019",
                code: "Kilifi Branch"
            },
            {
                branch: "020",
                code: "Office Park Westlands"
            },
            {
                branch: "021",
                code: "Barclaycard Operations"
            },
            {
                branch: "022",
                code: "Paymants And International Services"
            },
            {
                branch: "023",
                code: "Gilgil Branch"
            },
            {
                branch: "024",
                code: "Thika Road Mall"
            },
            {
                branch: "026",
                code: "Kakamega Branch"
            },
            {
                branch: "027",
                code: "Nakuru East"
            },
            {
                branch: "028",
                code: "Buruburu"
            },
            {
                branch: "029",
                code: "Bomet"
            },
            {
                branch: "030",
                code: "Nyeri Branch"
            },
            {
                branch: "031",
                code: "Thika Branch"
            },
            {
                branch: "032",
                code: "Port Mombasa"
            },
            {
                branch: "033",
                code: "Gikomba"
            },
            {
                branch: "034",
                code: "Kawangware"
            },
            {
                branch: "035",
                code: "Mbale"
            },
            {
                branch: "036",
                code: "Plaza Premier Centre"
            },
            {
                branch: "037",
                code: "River Road Branch"
            },
            {
                branch: "038",
                code: "Chomba House- River Road"
            },
            {
                branch: "039",
                code: "Mumias Branch"
            },
            {
                branch: "040",
                code: "Machakos Branch"
            },
            {
                branch: "041",
                code: "Narok Branch"
            },
            {
                branch: "042",
                code: "Isiolo Branch"
            },
            {
                branch: "043",
                code: "Ngong Branch"
            },
            {
                branch: "044",
                code: "Maua Branch"
            },
            {
                branch: "045",
                code: "Hurlingham Branch"
            },
            {
                branch: "046",
                code: "Makupa Branch"
            },
            {
                branch: "047",
                code: "Development House Branch"
            },
            {
                branch: "048",
                code: "Bungoma"
            },
            {
                branch: "049",
                code: "Lavington Branch"
            },
            {
                branch: "050",
                code: "Tala"
            },
            {
                branch: "051",
                code: "Homa Bay Branch"
            },
            {
                branch: "052",
                code: "Ongata Rongai Branch"
            },
            {
                branch: "053",
                code: "Othaya Branch"
            },
            {
                branch: "054",
                code: "Voi Branch"
            },
            {
                branch: "055",
                code: "Muthaiga Branch"
            },
            {
                branch: "056",
                code: "Barclays Advisory And Reg. Services"
            },
            {
                branch: "057",
                code: "Githunguri Branch"
            },
            {
                branch: "058",
                code: "Webuye Branch"
            },
            {
                branch: "059",
                code: "Kasarani Branch"
            },
            {
                branch: "060",
                code: "Chuka Branch"
            },
            {
                branch: "061",
                code: "Nakumatt-Westgate Branch"
            },
            {
                branch: "062",
                code: "Kabarnet Branch"
            },
            {
                branch: "063",
                code: "Kerugoya Branch"
            },
            {
                branch: "064",
                code: "Taveta Branch"
            },
            {
                branch: "065",
                code: "Karen Branch"
            },
            {
                branch: "066",
                code: "Wundanyi Branch"
            },
            {
                branch: "067",
                code: "Ruaraka Branch"
            },
            {
                branch: "068",
                code: "Kitengela Branch"
            },
            {
                branch: "069",
                code: "Wote Branch"
            },
            {
                branch: "070",
                code: "Enterprise Road Branch"
            },
            {
                branch: "071",
                code: "Nakumatt Meru Branch"
            },
            {
                branch: "072",
                code: "Juja Branch"
            },
            {
                branch: "073",
                code: "Westlands Branch"
            },
            {
                branch: "074",
                code: "Kikuyu Branch"
            },
            {
                branch: "075",
                code: "Moi Avenue-Nairobi Branch"
            },
            {
                branch: "076",
                code: "Nyali"
            },
            {
                branch: "077",
                code: "Absa Towers Branch"
            },
            {
                branch: "078",
                code: "Kiriaini Branch"
            },
            {
                branch: "079",
                code: "Butere Road Branch"
            },
            {
                branch: "080",
                code: "Migori Branch"
            },
            {
                branch: "081",
                code: "Digo Branch"
            },
            {
                branch: "082",
                code: "Haile Selassie Avenue Branch"
            },
            {
                branch: "083",
                code: "Nairobi University Branch"
            },
            {
                branch: "084",
                code: "Bunyala Road"
            },
            {
                branch: "086",
                code: "Nairobi West Branch"
            },
            {
                branch: "087",
                code: "Parklands"
            },
            {
                branch: "088",
                code: "Busia"
            },
            {
                branch: "089",
                code: "Pangani Branch"
            },
            {
                branch: "090",
                code: "Abc Premier Life Centre"
            },
            {
                branch: "093",
                code: "Kariobangi Branch"
            },
            {
                branch: "094",
                code: "Queensway House Branch"
            },
            {
                branch: "095",
                code: "Nakumatt Embakasi Branch"
            },
            {
                branch: "096",
                code: "Barclays Merchant Finance Ltd."
            },
            {
                branch: "097",
                code: "Barclays Securities Services (k"
            },
            {
                branch: "100",
                code: "Diani Branch"
            },
            {
                branch: "103",
                code: "Nairobi J.k.i.a Branch"
            },
            {
                branch: "105",
                code: "Village Market – Premier Life Centre"
            },
            {
                branch: "106",
                code: "Sarit Centre – Premier Life Centre"
            },
            {
                branch: "109",
                code: "Yaya Centre- Premier Life Centre"
            },
            {
                branch: "111",
                code: "Naivasha Branch"
            },
            {
                branch: "113",
                code: "Market Branch"
            },
            {
                branch: "114",
                code: "Changamwe Branch"
            },
            {
                branch: "117",
                code: "Rahimtulla Trust Towers – Premier Life Centre"
            },
            {
                branch: "125",
                code: "Nakuru West Branch"
            },
            {
                branch: "128",
                code: "Bamburi Branch"
            },
            {
                branch: "130",
                code: "Harambee Ave – Premier Life Centre"
            },
            {
                branch: "132",
                code: "Kitale Branch"
            },
            {
                branch: "139",
                code: "Nyahururu Branch"
            },
            {
                branch: "140",
                code: "Two Rivers"
            },
            {
                branch: "144",
                code: "Treasury Operations"
            },
            {
                branch: "145",
                code: "Moi Avenue Mombasa – Premier Life Centre"
            },
            {
                branch: "151",
                code: "Cash Monitoring Unit"
            },
            {
                branch: "190",
                code: "Nanyuki Branch"
            },
            {
                branch: "206",
                code: "Karatina Branch"
            },
            {
                branch: "220",
                code: "Mombasa Nyerere Ave – Premier Life Centre"
            }
        ]
    },
    {
        bank: "Citibank",
        code: [
            {
                branch: "Head Office, Nairobi",
                code: "000"
            },
            {
                branch: "Mombasa",
                code: "400"
            },
            {
                branch: "Gigiri Agency",
                code: "500"
            },
            {
                branch: "Kisumu",
                code: "700"
            }
        ]
    },
    {
        bank: "Consolidated Bank of Kenya",
        code: []
    },
    {
        bank: "Cooperative Bank of Kenya",
        code: [
            {
                branch: "Head Office",
                code: "000"
            },
            {
                branch: "Finance And Accounts",
                code: "001"
            },
            {
                branch: "Co-op House",
                code: "002"
            },
            {
                branch: "Kisumu",
                code: "003"
            },
            {
                branch: "Nkurumah Road",
                code: "004"
            },
            {
                branch: "Meru",
                code: "005"
            },
            {
                branch: "Nakuru",
                code: "006"
            },
            {
                branch: "Industrial Area",
                code: "007"
            },
            {
                branch: "Kisii",
                code: "008"
            },
            {
                branch: "Machakos",
                code: "009"
            },
            {
                branch: "Nyeri",
                code: "010"
            },
            {
                branch: "Ukulima",
                code: "011"
            },
            {
                branch: "Kerugoya",
                code: "012"
            },
            {
                branch: "Eldoret",
                code: "013"
            },
            {
                branch: "Moi Avenue",
                code: "014"
            },
            {
                branch: "Naivasha",
                code: "015"
            },
            {
                branch: "Nyahururu",
                code: "017"
            },
            {
                branch: "Chuka",
                code: "018"
            },
            {
                branch: "Wakulima Market",
                code: "019"
            },
            {
                branch: "Eastleigh",
                code: "020"
            },
            {
                branch: "Kiambu",
                code: "021"
            },
            {
                branch: "Homa Bay",
                code: "022"
            },
            {
                branch: "Embu",
                code: "023"
            },
            {
                branch: "Kericho",
                code: "024"
            },
            {
                branch: "Bungoma",
                code: "025"
            },
            {
                branch: "Muranga",
                code: "026"
            },
            {
                branch: "Kayole",
                code: "027"
            },
            {
                branch: "Karatina",
                code: "028"
            },
            {
                branch: "Ukunda",
                code: "029"
            },
            {
                branch: "Mtwapa",
                code: "030"
            },
            {
                branch: "University Way",
                code: "031"
            },
            {
                branch: "Buru Buru",
                code: "032"
            },
            {
                branch: "Athi River",
                code: "033"
            },
            {
                branch: "Mumias",
                code: "034"
            },
            {
                branch: "Stima Plaza",
                code: "035"
            },
            {
                branch: "Westlands",
                code: "036"
            },
            {
                branch: "Upper Hill",
                code: "037"
            },
            {
                branch: "Ongata Rongai",
                code: "038"
            },
            {
                branch: "Thika",
                code: "039"
            },
            {
                branch: "Nacico",
                code: "040"
            },
            {
                branch: "Kariobangi",
                code: "041"
            },
            {
                branch: "Kawangware",
                code: "042"
            },
            {
                branch: "Makutano",
                code: "043"
            },
            {
                branch: "Parliament road",
                code: "044"
            },
            {
                branch: "Kimathi Street",
                code: "045"
            },
            {
                branch: "Kitale",
                code: "046"
            },
            {
                branch: "Githurai",
                code: "047"
            },
            {
                branch: "Maua",
                code: "048"
            },
            {
                branch: "City Hall",
                code: "049"
            },
            {
                branch: "Digo Rd",
                code: "050"
            },
            {
                branch: "Nbc",
                code: "051"
            },
            {
                branch: "Kakamega",
                code: "052"
            },
            {
                branch: "Migori",
                code: "053"
            },
            {
                branch: "Kenyatta Avenue",
                code: "054"
            },
            {
                branch: "Nkubu",
                code: "055"
            },
            {
                branch: "Enterprise Road",
                code: "056"
            },
            {
                branch: "Busia",
                code: "057"
            },
            {
                branch: "Siaya",
                code: "058"
            },
            {
                branch: "Voi",
                code: "059"
            },
            {
                branch: "Mariakani",
                code: "060"
            },
            {
                branch: "Malindi",
                code: "061"
            },
            {
                branch: "Zimmerman",
                code: "062"
            },
            {
                branch: "Nakuru East",
                code: "063"
            },
            {
                branch: "Kitengela",
                code: "064"
            },
            {
                branch: "Aga Khan Walk",
                code: "065"
            },
            {
                branch: "Narok",
                code: "066"
            },
            {
                branch: "Kitui",
                code: "067"
            },
            {
                branch: "Nanyuki",
                code: "068"
            },
            {
                branch: "Embakasi",
                code: "069"
            },
            {
                branch: "Kibera",
                code: "070"
            },
            {
                branch: "Siakago",
                code: "071"
            },
            {
                branch: "Kapsabet",
                code: "072"
            },
            {
                branch: "Mbita",
                code: "073"
            },
            {
                branch: "Kangemi",
                code: "074"
            },
            {
                branch: "Dandora",
                code: "075"
            },
            {
                branch: "Kajiado",
                code: "076"
            },
            {
                branch: "Tala",
                code: "077"
            },
            {
                branch: "Gikomba",
                code: "078"
            },
            {
                branch: "River Road",
                code: "079"
            },
            {
                branch: "Nyamira",
                code: "080"
            },
            {
                branch: "Garisa",
                code: "081"
            },
            {
                branch: "Bomet",
                code: "082"
            },
            {
                branch: "Keroka",
                code: "083"
            },
            {
                branch: "Gilgil",
                code: "084"
            },
            {
                branch: "Tom Mboya",
                code: "085"
            },
            {
                branch: "Likoni",
                code: "086"
            },
            {
                branch: "Donholm",
                code: "087"
            },
            {
                branch: "Mwingi",
                code: "088"
            },
            {
                branch: "Ruaka",
                code: "089"
            },
            {
                branch: "Webuye",
                code: "090"
            },
            {
                branch: "Sacco Clearing Unit",
                code: "094"
            },
            {
                branch: "clearing Centre",
                code: "097"
            },
            {
                branch: "Ndhiwa",
                code: "100"
            },
            {
                branch: "Oyugis",
                code: "101"
            },
            {
                branch: "Isiolo",
                code: "102"
            },
            {
                branch: "Eldoret West",
                code: "103"
            },
            {
                branch: "Changamwe",
                code: "104"
            },
            {
                branch: "Kisumu East",
                code: "105"
            },
            {
                branch: "Githurai Kimbo",
                code: "106"
            },
            {
                branch: "Mlolongo",
                code: "107"
            },
            {
                branch: "Kilifi",
                code: "108"
            },
            {
                branch: "Ol Kalau",
                code: "109"
            },
            {
                branch: "Mbale",
                code: "110"
            },
            {
                branch: "Kimilili",
                code: "111"
            },
            {
                branch: "Kisii East",
                code: "112"
            },
            {
                branch: "Kilgoris",
                code: "113"
            },
            {
                branch: "Wote",
                code: "114"
            },
            {
                branch: "Malaba",
                code: "116"
            },
            {
                branch: "Molo",
                code: "117"
            },
            {
                branch: "Mwea",
                code: "118"
            },
            {
                branch: "Kutus",
                code: "119"
            },
            {
                branch: "Umoja",
                code: "120"
            },
            {
                branch: "Embakasi Junction",
                code: "121"
            },
            {
                branch: "Kongowea",
                code: "122"
            },
            {
                branch: "Langata Road",
                code: "123"
            },
            {
                branch: "Juja",
                code: "124"
            },
            {
                branch: "Ngong",
                code: "125"
            },
            {
                branch: "Kawangware 46",
                code: "126"
            },
            {
                branch: "Mombasa Road",
                code: "127"
            },
            {
                branch: "Marsabit",
                code: "128"
            },
            {
                branch: "Dagoretti",
                code: "130"
            },
            {
                branch: "Othaya",
                code: "131"
            },
            {
                branch: "Limuru",
                code: "132"
            },
            {
                branch: "Kikuyu",
                code: "133"
            },
            {
                branch: "Githunguri",
                code: "134"
            },
            {
                branch: "Karen",
                code: "135"
            },
            {
                branch: "Mpeketoni",
                code: "136"
            },
            {
                branch: "Gatundu",
                code: "137"
            },
            {
                branch: "Ruiru",
                code: "138"
            },
            {
                branch: "Nyali Mall",
                code: "139"
            },
            {
                branch: "Yala",
                code: "140"
            },
            {
                branch: "Maasai Mall – Ongata Rongai",
                code: "141"
            },
            {
                branch: "Thika Road Mall",
                code: "142"
            },
            {
                branch: "Kiserian",
                code: "143"
            },
            {
                branch: "Nandi Hills",
                code: "144"
            },
            {
                branch: "Lodwar",
                code: "145"
            },
            {
                branch: "Engineer",
                code: "147"
            },
            {
                branch: "Rongo",
                code: "148"
            },
            {
                branch: "Lavington Mall",
                code: "149"
            },
            {
                branch: "Bondo",
                code: "150"
            },
            {
                branch: "Gigiri Mall",
                code: "151"
            },
            {
                branch: "United Mall, Kisumu",
                code: "152"
            },
            {
                branch: "Green House Mall",
                code: "153"
            },
            {
                branch: "Gikomba Area 42",
                code: "154"
            },
            {
                branch: "Utawala",
                code: "155"
            },
            {
                branch: "Ridgeways Mall",
                code: "156"
            },
            {
                branch: "Two Rivers Mall",
                code: "157"
            },
            {
                branch: "JKIA",
                code: "158"
            },
            {
                branch: "Emali",
                code: "159"
            },
            {
                branch: "Kangema",
                code: "160"
            },
            {
                branch: "Kilimani",
                code: "161"
            },
            {
                branch: "Litein",
                code: "162"
            },
            {
                branch: "Thika Makongeni",
                code: "163"
            },
            {
                branch: "Kenyatta Avenue",
                code: "164"
            },
            {
                branch: "Chogoria",
                code: "165"
            },
            {
                branch: "Kapenguria Sub – Branch",
                code: "166"
            },
            {
                branch: "Maralal",
                code: "167"
            },
            {
                branch: "Shares Operations",
                code: "228"
            },
            {
                branch: "Back Office Operations",
                code: "247"
            },
            {
                branch: "E- Channels Unit",
                code: "250"
            },
            {
                branch: "Diaspora Banking",
                code: "254"
            },
            {
                branch: "Kilindini Port",
                code: "266"
            },
            {
                branch: "Money Transfers Agency",
                code: "270"
            }
        ]
    },
    {
        bank: "Credit Bank",
        code: [
            {
                branch: "Branch name",
                code: "Branch code"
            },
            {
                branch: "Head Office",
                code: "000"
            },
            {
                branch: "Koinange Street",
                code: "001"
            },
            {
                branch: "Kisumu",
                code: "002"
            },
            {
                branch: "Nakuru",
                code: "003"
            },
            {
                branch: "Kisii",
                code: "004"
            },
            {
                branch: "Westlands",
                code: "005"
            },
            {
                branch: "Industrial Area",
                code: "006"
            },
            {
                branch: "Nakuru, Kenyatta Avenue",
                code: "008"
            },
            {
                branch: "Eldoret",
                code: "009"
            },
            {
                branch: "Rongai",
                code: "010"
            },
            {
                branch: "Mombasa- Nyali Centre",
                code: "011"
            },
            {
                branch: "Thika",
                code: "012"
            },
            {
                branch: "Lavington Mall",
                code: "013"
            },
            {
                branch: "Machakos",
                code: "014"
            },
            {
                branch: "Kitengela",
                code: "015"
            },
            {
                branch: "Stemuki Plaza",
                code: "016"
            },
            {
                branch: "Ngong Road",
                code: "017"
            },
            {
                branch: "Nkuruma",
                code: "018"
            },
            {
                branch: "One Africa",
                code: "019"
            },
            {
                branch: "Sumac Koinange",
                code: "500"
            }
        ]
    },
    {
        bank: "Diamond Trust Bank",
        code: [
            {
                branch: "Branch code",
                code: "Branch name"
            },
            {
                branch: "055",
                code: "9 West"
            },
            {
                branch: "056",
                code: "Biashara Street, Nakuru"
            },
            {
                branch: "021",
                code: "Bungoma"
            },
            {
                branch: "024",
                code: "Buru Buru"
            },
            {
                branch: "048",
                code: "Busia"
            },
            {
                branch: "016",
                code: "Changamwe"
            },
            {
                branch: "041",
                code: "Courtyard Branch"
            },
            {
                branch: "036",
                code: "Crossroad"
            },
            {
                branch: "035",
                code: "Diamond Plaza"
            },
            {
                branch: "020",
                code: "Diani"
            },
            {
                branch: "052",
                code: "DTB Centre"
            },
            {
                branch: "015",
                code: "Eastleigh"
            },
            {
                branch: "014",
                code: "Eldoret"
            },
            {
                branch: "068",
                code: "Eldoret Kago Street"
            },
            {
                branch: "059",
                code: "Embu"
            },
            {
                branch: "071",
                code: "Enterprise Road"
            },
            {
                branch: "057",
                code: "Garden City Mall"
            },
            {
                branch: "000",
                code: "Head Office"
            },
            {
                branch: "009",
                code: "Industrial  Area"
            },
            {
                branch: "073",
                code: "Jamhuri Street Malindi"
            },
            {
                branch: "037",
                code: "JKIA"
            },
            {
                branch: "026",
                code: "Jomo Kenyatta Branch Mombasa"
            },
            {
                branch: "027",
                code: "Kakamega"
            },
            {
                branch: "031",
                code: "Karen"
            },
            {
                branch: "028",
                code: "Kericho"
            },
            {
                branch: "044",
                code: "Kilifi Branch"
            },
            {
                branch: "010",
                code: "Kisii"
            },
            {
                branch: "003",
                code: "Kisumu"
            },
            {
                branch: "022",
                code: "Kitale"
            },
            {
                branch: "025",
                code: "Kitengela"
            },
            {
                branch: "069",
                code: "Koinange Street"
            },
            {
                branch: "043",
                code: "Lamu Branch"
            },
            {
                branch: "054",
                code: "Lavington"
            },
            {
                branch: "060",
                code: "Machakos"
            },
            {
                branch: "040",
                code: "Madina Mall"
            },
            {
                branch: "011",
                code: "Malindi"
            },
            {
                branch: "045",
                code: "Mariakani Branch"
            },
            {
                branch: "034",
                code: "Meru"
            },
            {
                branch: "039",
                code: "Migori Branch"
            },
            {
                branch: "002",
                code: "Mombasa"
            },
            {
                branch: "008",
                code: "Mombasa Rd"
            },
            {
                branch: "042",
                code: "Mtwapa Branch"
            },
            {
                branch: "070",
                code: "Mwanzi Road"
            },
            {
                branch: "018",
                code: "Nakuru"
            },
            {
                branch: "061",
                code: "Narok"
            },
            {
                branch: "001",
                code: "Nation Centre"
            },
            {
                branch: "062",
                code: "Ngong Milele Mall"
            },
            {
                branch: "072",
                code: "Nkrumah Road Mombasa"
            },
            {
                branch: "038",
                code: "Nyali"
            },
            {
                branch: "067",
                code: "Nyeri"
            },
            {
                branch: "013",
                code: "OTC"
            },
            {
                branch: "005",
                code: "Parklands"
            },
            {
                branch: "023",
                code: "Prestige"
            },
            {
                branch: "066",
                code: "Rivaan Center"
            },
            {
                branch: "065",
                code: "Riverside"
            },
            {
                branch: "047",
                code: "Ronald Ngala"
            },
            {
                branch: "033",
                code: "Shimanzi"
            },
            {
                branch: "053",
                code: "South C"
            },
            {
                branch: "017",
                code: "T-mall"
            },
            {
                branch: "058",
                code: "The Oval"
            },
            {
                branch: "012",
                code: "Thika"
            },
            {
                branch: "046",
                code: "Thika Road Mall"
            },
            {
                branch: "050",
                code: "Tom Mboya"
            },
            {
                branch: "063",
                code: "Two Rivers"
            },
            {
                branch: "029",
                code: "Upper Hill"
            },
            {
                branch: "019",
                code: "Village Market"
            },
            {
                branch: "032",
                code: "Voi"
            },
            {
                branch: "030",
                code: "Wabera Street"
            },
            {
                branch: "064",
                code: "Watamu"
            },
            {
                branch: "049",
                code: "West End Mall – Kisumu"
            },
            {
                branch: "006",
                code: "Westgate"
            }
        ]
    },
    {
        bank: "Ecobank",
        code: [
            {
                branch: "Plaza 2000",
                code: "003"
            },
            {
                branch: "Westminister",
                code: "004"
            },
            {
                branch: "Chambers",
                code: "005"
            },
            {
                branch: "Thika",
                code: "006"
            },
            {
                branch: "Eldoret",
                code: "007"
            },
            {
                branch: "Kisumu",
                code: "008"
            },
            {
                branch: "Kisii",
                code: "009"
            },
            {
                branch: "Kitale",
                code: "010"
            },
            {
                branch: "Industrial Area",
                code: "011"
            },
            {
                branch: "Karatina",
                code: "012"
            },
            {
                branch: "Westlands",
                code: "013"
            },
            {
                branch: "United Mall",
                code: "014"
            },
            {
                branch: "Nakuru",
                code: "015"
            },
            {
                branch: "Jomo Kenyatta Avenue",
                code: "016"
            },
            {
                branch: "Nyeri",
                code: "017"
            },
            {
                branch: "Busia",
                code: "018"
            },
            {
                branch: "Malindi",
                code: "019"
            },
            {
                branch: "Meru",
                code: "020"
            },
            {
                branch: "Gikomba",
                code: "021"
            },
            {
                branch: "UpperHill",
                code: "022"
            },
            {
                branch: "Valley Arcade",
                code: "023"
            },
            {
                branch: "Karen",
                code: "024"
            },
            {
                branch: "Nyali Mombasa",
                code: "025"
            },
            {
                branch: "Ongata Rongai",
                code: "026"
            },
            {
                branch: "Embakasi",
                code: "027"
            },
            {
                branch: "Kitengela",
                code: "028"
            },
            {
                branch: "Thika Mall",
                code: "029"
            },
            {
                branch: "Fortis Office Park",
                code: "030"
            },
            {
                branch: "Head Office",
                code: "100"
            }
        ]
    },
    {
        bank: "Equity Bank",
        code: [
            {
                branch: "Equity Bank Head Office",
                code: "000"
            },
            {
                branch: "Equity Bank Corporate",
                code: "001"
            },
            {
                branch: "Equity Bank Fourways",
                code: "002"
            },
            {
                branch: "Kangema",
                code: "003"
            },
            {
                branch: "Karatina",
                code: "004"
            },
            {
                branch: "Kiriaini",
                code: "005"
            },
            {
                branch: "Murarandia",
                code: "006"
            },
            {
                branch: "Kangari",
                code: "007"
            },
            {
                branch: "Othaya",
                code: "008"
            },
            {
                branch: "Thika / Equity Plaza",
                code: "009"
            },
            {
                branch: "Kerugoya",
                code: "010"
            },
            {
                branch: "Nyeri",
                code: "011"
            },
            {
                branch: "Tom Mboya",
                code: "012"
            },
            {
                branch: "Nakuru",
                code: "013"
            },
            {
                branch: "Meru",
                code: "014"
            },
            {
                branch: "Mama Ngina",
                code: "015"
            },
            {
                branch: "Nyahururu",
                code: "016"
            },
            {
                branch: "Community",
                code: "017"
            },
            {
                branch: "Community Corporate",
                code: "018"
            },
            {
                branch: "Embu",
                code: "019"
            },
            {
                branch: "Naivasha",
                code: "020"
            },
            {
                branch: "Chuka",
                code: "021"
            },
            {
                branch: "Muranga",
                code: "022"
            },
            {
                branch: "Molo",
                code: "023"
            },
            {
                branch: "Harambee Avenu",
                code: "024"
            },
            {
                branch: "Mombasa",
                code: "025"
            },
            {
                branch: "Kimathi Street",
                code: "026"
            },
            {
                branch: "Nanyuki",
                code: "027"
            },
            {
                branch: "Kericho",
                code: "028"
            },
            {
                branch: "Kisumu",
                code: "029"
            },
            {
                branch: "Eldoret",
                code: "030"
            },
            {
                branch: "Nakuru Kenyatta Avenue",
                code: "031"
            },
            {
                branch: "Kariobangi",
                code: "032"
            },
            {
                branch: "Kitale",
                code: "033"
            },
            {
                branch: "Thika Kenyatta Avenue",
                code: "034"
            },
            {
                branch: "Knut House",
                code: "035"
            },
            {
                branch: "Narok",
                code: "036"
            },
            {
                branch: "Nkubu",
                code: "037"
            },
            {
                branch: "Mwea",
                code: "038"
            },
            {
                branch: "Matuu",
                code: "039"
            },
            {
                branch: "Maua",
                code: "040"
            },
            {
                branch: "Isiolo",
                code: "041"
            },
            {
                branch: "Kagio",
                code: "042"
            },
            {
                branch: "Gikomba",
                code: "043"
            },
            {
                branch: "Ukunda",
                code: "044"
            },
            {
                branch: "Malindi",
                code: "045"
            },
            {
                branch: "Mombasa Digo Road",
                code: "046"
            },
            {
                branch: "Moi Avenue",
                code: "047"
            },
            {
                branch: "Bungoma",
                code: "048"
            },
            {
                branch: "Kapsabet",
                code: "049"
            },
            {
                branch: "Kakamega",
                code: "050"
            },
            {
                branch: "Kisii",
                code: "051"
            },
            {
                branch: "Nyamira",
                code: "052"
            },
            {
                branch: "Litein",
                code: "053"
            },
            {
                branch: "Equity Centre Diaspora",
                code: "054"
            },
            {
                branch: "Westlands",
                code: "055"
            },
            {
                branch: "Industrial Area Kenpipe Plaza",
                code: "056"
            },
            {
                branch: "Kikuyu",
                code: "057"
            },
            {
                branch: "Garissa",
                code: "058"
            },
            {
                branch: "Mwingi",
                code: "059"
            },
            {
                branch: "Machakos",
                code: "060"
            },
            {
                branch: "Ongata Rongai",
                code: "061"
            },
            {
                branch: "Ol-kalao",
                code: "062"
            },
            {
                branch: "Kawangware",
                code: "063"
            },
            {
                branch: "Kiambu",
                code: "064"
            },
            {
                branch: "Kayole",
                code: "065"
            },
            {
                branch: "Gatundu",
                code: "066"
            },
            {
                branch: "Wote",
                code: "067"
            },
            {
                branch: "Mumias",
                code: "068"
            },
            {
                branch: "Limuru",
                code: "069"
            },
            {
                branch: "Kitengela",
                code: "070"
            },
            {
                branch: "Githurai",
                code: "071"
            },
            {
                branch: "Kitui",
                code: "072"
            },
            {
                branch: "Ngong",
                code: "073"
            },
            {
                branch: "Loitoktok",
                code: "074"
            },
            {
                branch: "Bondo",
                code: "075"
            },
            {
                branch: "Mbita",
                code: "076"
            },
            {
                branch: "Gilgil",
                code: "077"
            },
            {
                branch: "Busia",
                code: "078"
            },
            {
                branch: "Voi",
                code: "079"
            },
            {
                branch: "Enterprise Road",
                code: "080"
            },
            {
                branch: "Equity Centre",
                code: "081"
            },
            {
                branch: "Donholm",
                code: "082"
            },
            {
                branch: "Mukurwe-ini",
                code: "083"
            },
            {
                branch: "Eastleigh",
                code: "084"
            },
            {
                branch: "Namanga",
                code: "085"
            },
            {
                branch: "Kajiado",
                code: "086"
            },
            {
                branch: "Ruiru",
                code: "087"
            },
            {
                branch: "Otc",
                code: "088"
            },
            {
                branch: "Kenol",
                code: "089"
            },
            {
                branch: "Tala",
                code: "090"
            },
            {
                branch: "Ngara",
                code: "091"
            },
            {
                branch: "Nandi Hills",
                code: "092"
            },
            {
                branch: "Githunguri",
                code: "093"
            },
            {
                branch: "Tea Room",
                code: "094"
            },
            {
                branch: "Buru Buru",
                code: "095"
            },
            {
                branch: "Mbale",
                code: "096"
            },
            {
                branch: "Siaya",
                code: "097"
            },
            {
                branch: "Homa Bay",
                code: "098"
            },
            {
                branch: "Lodwar",
                code: "099"
            },
            {
                branch: "Mandera",
                code: "100"
            },
            {
                branch: "Marsabit",
                code: "101"
            },
            {
                branch: "Moyale",
                code: "102"
            },
            {
                branch: "Wajir",
                code: "103"
            },
            {
                branch: "Meru Makutano",
                code: "104"
            },
            {
                branch: "Malaba Town",
                code: "105"
            },
            {
                branch: "Kilifi",
                code: "106"
            },
            {
                branch: "Kapenguria",
                code: "107"
            },
            {
                branch: "Mombasa Road",
                code: "108"
            },
            {
                branch: "Eldoret Market",
                code: "109"
            },
            {
                branch: "Maralal",
                code: "110"
            },
            {
                branch: "Kimende",
                code: "111"
            },
            {
                branch: "Luanda",
                code: "112"
            },
            {
                branch: "Ku Sub Branch",
                code: "113"
            },
            {
                branch: "Kengeleni",
                code: "114"
            },
            {
                branch: "Nyeri Kimathi Way",
                code: "115"
            },
            {
                branch: "Migori",
                code: "116"
            },
            {
                branch: "Kibera",
                code: "117"
            },
            {
                branch: "Kasarani",
                code: "118"
            },
            {
                branch: "Mtwapa",
                code: "119"
            },
            {
                branch: "Changamwe",
                code: "120"
            },
            {
                branch: "Hola",
                code: "121"
            },
            {
                branch: "Bomet",
                code: "122"
            },
            {
                branch: "Kilgoris",
                code: "123"
            },
            {
                branch: "Keroka",
                code: "124"
            },
            {
                branch: "KAREN",
                code: "125"
            },
            {
                branch: "KISUMU ANGAWA AVE",
                code: "126"
            },
            {
                branch: "MPEKETONI",
                code: "127"
            },
            {
                branch: "NAIROBI WEST",
                code: "128"
            },
            {
                branch: "KENYATTA AVENUE",
                code: "129"
            },
            {
                branch: "City Hall",
                code: "130"
            },
            {
                branch: "Eldama Ravine",
                code: "131"
            },
            {
                branch: "Embakasi",
                code: "132"
            },
            {
                branch: "KPCU",
                code: "133"
            },
            {
                branch: "Ridgeways",
                code: "134"
            },
            {
                branch: "Runyenjes Sub Branch",
                code: "135"
            },
            {
                branch: "Dadaad",
                code: "136"
            },
            {
                branch: "Kangemi",
                code: "137"
            },
            {
                branch: "Nyali Centre Corporate",
                code: "138"
            },
            {
                branch: "Kabarnet",
                code: "139"
            },
            {
                branch: "Westlands Corporate",
                code: "140"
            },
            {
                branch: "Lavington Corporate",
                code: "141"
            },
            {
                branch: "Taita Taveta",
                code: "142"
            },
            {
                branch: "Awendo",
                code: "143"
            },
            {
                branch: "Ruai",
                code: "144"
            },
            {
                branch: "Kilimani",
                code: "145"
            },
            {
                branch: "Nakuru Westside Mall",
                code: "146"
            },
            {
                branch: "Kilimani Supreme",
                code: "147"
            },
            {
                branch: "JKIA Cargo Centre",
                code: "148"
            },
            {
                branch: "EPZ Athi River",
                code: "149"
            },
            {
                branch: "Oyugis",
                code: "150"
            },
            {
                branch: "Mayfair Supreme Centre",
                code: "151"
            },
            {
                branch: "Juja",
                code: "152"
            },
            {
                branch: "Iten",
                code: "153"
            },
            {
                branch: "Nyali Supreme Centre",
                code: "154"
            },
            {
                branch: "Thika Supreme Centre",
                code: "155"
            },
            {
                branch: "Mombasa Supreme Centre",
                code: "156"
            },
            {
                branch: "Kapsowar Sub-Branch",
                code: "157"
            },
            {
                branch: "Kwale",
                code: "158"
            },
            {
                branch: "Lamu",
                code: "159"
            },
            {
                branch: "Kenyatta Avenue Supreme",
                code: "160"
            },
            {
                branch: "KPA Sub-Branch Mombasa",
                code: "161"
            },
            {
                branch: "Gigiri Supreme Centre",
                code: "162"
            },
            {
                branch: "Karen Supreme Centre",
                code: "163"
            },
            {
                branch: "Eldoret Supreme Centre",
                code: "164"
            },
            {
                branch: "Kakuma",
                code: "165"
            },
            {
                branch: "Archers Post",
                code: "166"
            },
            {
                branch: "Mutomo",
                code: "167"
            },
            {
                branch: "Kiserian Sub Branch",
                code: "168"
            },
            {
                branch: "Dagoretti Corner",
                code: "169"
            },
            {
                branch: "Kisumu Supreme Centre",
                code: "170"
            },
            {
                branch: "Thika Makongeni",
                code: "171"
            },
            {
                branch: "Kutus Sub – Branch",
                code: "172"
            },
            {
                branch: "Kibwezi Sub – Branch",
                code: "173"
            },
            {
                branch: "Ruaka",
                code: "174"
            },
            {
                branch: "Garden City",
                code: "175"
            },
            {
                branch: "Mlolongo",
                code: "176"
            },
            {
                branch: "Embassy House",
                code: "177"
            },
            {
                branch: "Wangige Sub Branch",
                code: "178"
            },
            {
                branch: "Equity Hapo Hapo",
                code: "777"
            }
        ]
    },
    {
        bank: "Family Bank",
        code: [
            {
                branch: "Head Office",
                code: "000"
            },
            {
                branch: "Kiambu",
                code: "001"
            },
            {
                branch: "Githunguri",
                code: "002"
            },
            {
                branch: "Sonalux",
                code: "003"
            },
            {
                branch: "Gatundu",
                code: "004"
            },
            {
                branch: "Thika",
                code: "005"
            },
            {
                branch: "Muranga",
                code: "006"
            },
            {
                branch: "Kangari",
                code: "007"
            },
            {
                branch: "Kiria-ini",
                code: "008"
            },
            {
                branch: "Kangema",
                code: "009"
            },
            {
                branch: "JKIA",
                code: "010"
            },
            {
                branch: "Othaya",
                code: "011"
            },
            {
                branch: "Kenyatta Avenue",
                code: "012"
            },
            {
                branch: "Cargen House",
                code: "014"
            },
            {
                branch: "Laptrust",
                code: "015"
            },
            {
                branch: "City Hall Annex",
                code: "016"
            },
            {
                branch: "Kasarani",
                code: "017"
            },
            {
                branch: "Nakuru Finance House",
                code: "018"
            },
            {
                branch: "Nakuru Market",
                code: "019"
            },
            {
                branch: "Kutus",
                code: "020"
            },
            {
                branch: "Dagoretti",
                code: "021"
            },
            {
                branch: "Kericho",
                code: "022"
            },
            {
                branch: "Nyahururu",
                code: "023"
            },
            {
                branch: "Ruiru",
                code: "024"
            },
            {
                branch: "Kisumu Reliance",
                code: "025"
            },
            {
                branch: "Nyamira",
                code: "026"
            },
            {
                branch: "Kisii",
                code: "027"
            },
            {
                branch: "Kisumu Al Imran",
                code: "028"
            },
            {
                branch: "Narok",
                code: "029"
            },
            {
                branch: "Kangemi",
                code: "030"
            },
            {
                branch: "Industrial Area",
                code: "031"
            },
            {
                branch: "Thika Makongeni",
                code: "032"
            },
            {
                branch: "Donholm",
                code: "033"
            },
            {
                branch: "Utawala",
                code: "034"
            },
            {
                branch: "Fourways Retail Branch",
                code: "035"
            },
            {
                branch: "Mlolongo",
                code: "036"
            },
            {
                branch: "Olkalou",
                code: "037"
            },
            {
                branch: "Ktda Plaza",
                code: "038"
            },
            {
                branch: "Gateway Mall Nairobi",
                code: "039"
            },
            {
                branch: "Kariobangi",
                code: "041"
            },
            {
                branch: "Gikomba Area 42",
                code: "042"
            },
            {
                branch: "Sokoni",
                code: "043"
            },
            {
                branch: "Kahawa West",
                code: "044"
            },
            {
                branch: "Githurai",
                code: "045"
            },
            {
                branch: "Yaya",
                code: "046"
            },
            {
                branch: "Limuru",
                code: "047"
            },
            {
                branch: "Westlands",
                code: "048"
            },
            {
                branch: "Kagwe",
                code: "049"
            },
            {
                branch: "Banana",
                code: "051"
            },
            {
                branch: "Ruaka",
                code: "052"
            },
            {
                branch: "Naivasha",
                code: "053"
            },
            {
                branch: "Chuka",
                code: "054"
            },
            {
                branch: "Nyeri",
                code: "055"
            },
            {
                branch: "Karatina",
                code: "056"
            },
            {
                branch: "Kerugoya",
                code: "057"
            },
            {
                branch: "Tom Mboya",
                code: "058"
            },
            {
                branch: "River Road",
                code: "059"
            },
            {
                branch: "Kayole",
                code: "061"
            },
            {
                branch: "Nkubu",
                code: "062"
            },
            {
                branch: "Meru",
                code: "063"
            },
            {
                branch: "Nanyuki",
                code: "064"
            },
            {
                branch: "Ktda Plaza Corporate",
                code: "065"
            },
            {
                branch: "Ongata Rongai",
                code: "066"
            },
            {
                branch: "Kajiado",
                code: "067"
            },
            {
                branch: "Fourways Corporate Branch",
                code: "068"
            },
            {
                branch: "Ngara",
                code: "069"
            },
            {
                branch: "Kitengela",
                code: "071"
            },
            {
                branch: "Kitui",
                code: "072"
            },
            {
                branch: "Mackakos",
                code: "073"
            },
            {
                branch: "Migori",
                code: "074"
            },
            {
                branch: "Embu",
                code: "075"
            },
            {
                branch: "Mwea",
                code: "076"
            },
            {
                branch: "Bungoma",
                code: "077"
            },
            {
                branch: "Kakamega",
                code: "078"
            },
            {
                branch: "Busia",
                code: "079"
            },
            {
                branch: "Mumias",
                code: "081"
            },
            {
                branch: "Eldoret West",
                code: "082"
            },
            {
                branch: "Molo",
                code: "083"
            },
            {
                branch: "Bomet",
                code: "084"
            },
            {
                branch: "Eldoret",
                code: "085"
            },
            {
                branch: "Maua",
                code: "086"
            },
            {
                branch: "Litein",
                code: "087"
            },
            {
                branch: "Wote",
                code: "088"
            },
            {
                branch: "Bamburi",
                code: "089"
            },
            {
                branch: "Ukunda",
                code: "091"
            },
            {
                branch: "Digo",
                code: "092"
            },
            {
                branch: "Kitale",
                code: "093"
            },
            {
                branch: "Mtwapa",
                code: "094"
            },
            {
                branch: "Mombasa Nkrumah Road",
                code: "095"
            },
            {
                branch: "Mombasa Jomo Kenyatta Avenue",
                code: "096"
            },
            {
                branch: "Kapsabet",
                code: "097"
            },
            {
                branch: "Malindi",
                code: "098"
            },
            {
                branch: "Kikuyu",
                code: "102"
            }
        ]
    },
    {
        bank: "First Community Bank",
        code: [
            {
                branch: "74001",
                code: "Wabera Street"
            },
            {
                branch: "74002",
                code: "Eastleigh 1"
            },
            {
                branch: "74003",
                code: "Mombasa 1"
            },
            {
                branch: "74004",
                code: "Garissa"
            },
            {
                branch: "74005",
                code: "Eastleigh 2 – General Waruing"
            },
            {
                branch: "74006",
                code: "Malindi"
            },
            {
                branch: "74007",
                code: "Kisumu"
            },
            {
                branch: "74008",
                code: "Kimathi Street"
            },
            {
                branch: "74009",
                code: "Westlands"
            },
            {
                branch: "74010",
                code: "South C"
            },
            {
                branch: "74011",
                code: "Industrial Area"
            },
            {
                branch: "74012",
                code: "Masalani"
            },
            {
                branch: "74013",
                code: "Habasweni"
            },
            {
                branch: "74014",
                code: "Wajir"
            },
            {
                branch: "74015",
                code: "Moyale"
            },
            {
                branch: "74016",
                code: "Nakuru"
            },
            {
                branch: "74017",
                code: "Imaara Building"
            },
            {
                branch: "74018",
                code: "Lunga Lunga"
            },
            {
                branch: "74019",
                code: "Isiolo"
            },
            {
                branch: "74020",
                code: "Mihrab"
            },
            {
                branch: "74999",
                code: "Head Office/Clearing Center"
            }
        ]
    },
    {
        bank: "Guaranty Trust Bank",
        code: [
            {
                branch: "53000",
                code: "Head Office"
            },
            {
                branch: "53001",
                code: "Kimathi Street"
            },
            {
                branch: "53002",
                code: "Ind Area"
            },
            {
                branch: "53003",
                code: "Westlands"
            },
            {
                branch: "53004",
                code: "Lavington"
            },
            {
                branch: "53005",
                code: "Nkrumah Road, Mombasa"
            },
            {
                branch: "53006",
                code: "Nakuru"
            },
            {
                branch: "53007",
                code: "Eldoret"
            },
            {
                branch: "53008",
                code: "Muthaiga"
            },
            {
                branch: "53009",
                code: "Nanyuki"
            },
            {
                branch: "53010",
                code: "Thika"
            },
            {
                branch: "53011",
                code: "Gikomba"
            },
            {
                branch: "53013",
                code: "Meru"
            },
            {
                branch: "53014",
                code: "Nyali"
            },
            {
                branch: "53015",
                code: "Sky Park"
            },
            {
                branch: "53016",
                code: "Karen"
            }
        ]
    },
    {
        bank: "Guardian Bank",
        code: ""
    },
    {
        bank: "Gulf African Bank",
        code: ""
    },
    {
        bank: "Habib Bank",
        code: ""
    },
    {
        bank: "HFC",
        code: ""
    },
    {
        bank: "I & M Bank",
        code: ""
    },
    {
        bank: "Jamii Bora Bank",
        code: [
            {
                branch: "Head Office",
                code: "000"
            },
            {
                branch: "Koinange Street",
                code: "001"
            },
            {
                branch: "Kiongozi",
                code: "100"
            },
            {
                branch: "Kayole",
                code: "101"
            },
            {
                branch: "Eastleigh",
                code: "102"
            },
            {
                branch: "Kawangware",
                code: "105"
            },
            {
                branch: "Kibera",
                code: "106"
            },
            {
                branch: "Kariobangi",
                code: "107"
            },
            {
                branch: "Central Clearing Center",
                code: "111"
            },
            {
                branch: "Funzi Road",
                code: "114"
            },
            {
                branch: "Ngong Road",
                code: "115"
            },
            {
                branch: "Kirinyaga Road",
                code: "116"
            },
            {
                branch: "Machakos",
                code: "209"
            },
            {
                branch: "Mtwapa",
                code: "210"
            },
            {
                branch: "Kiritiri",
                code: "213"
            },
            {
                branch: "Thika",
                code: "301"
            },
            {
                branch: "Muranga",
                code: "303"
            },
            {
                branch: "Wangige",
                code: "305"
            },
            {
                branch: "Kikuyu",
                code: "306"
            },
            {
                branch: "Banana",
                code: "307"
            },
            {
                branch: "Kiambu",
                code: "308"
            },
            {
                branch: "Utawala",
                code: "310"
            },
            {
                branch: "Nyeri",
                code: "316"
            },
            {
                branch: "SACCO Clearing Centre",
                code: "318"
            },
            {
                branch: "Kisumu",
                code: "402"
            },
            {
                branch: "Kisii",
                code: "409"
            },
            {
                branch: "Ongata Rongai",
                code: "502"
            },
            {
                branch: "Kitengela",
                code: "503"
            },
            {
                branch: "Nakuru",
                code: "507"
            },
            {
                branch: "Mombasa",
                code: "603"
            }
        ]
    },
    {
        bank: "Kenya Commercial Bank Ltd",
        code: [
            {
                branch: "Biashara Street",
                code: "01263"
            },
            {
                branch: "Bomet",
                code: "01181"
            },
            {
                branch: "Bondo",
                code: "01228"
            },
            {
                branch: "Bungoma",
                code: "01197"
            },
            {
                branch: "Buruburu",
                code: "01205"
            },
            {
                branch: "Busia",
                code: "01229"
            },
            {
                branch: "Capital Hill",
                code: "01201"
            },
            {
                branch: "Card Centre",
                code: "01139"
            },
            {
                branch: "Changamwe",
                code: "01315"
            },
            {
                branch: "Chogoria",
                code: "01206"
            },
            {
                branch: "Chuka",
                code: "01196"
            },
            {
                branch: "Cpc Bulk Corporate Cheques",
                code: "01292"
            },
            {
                branch: "Diani",
                code: "01335"
            },
            {
                branch: "Diaspora",
                code: "01302"
            },
            {
                branch: "Eastleigh",
                code: "01091"
            },
            {
                branch: "Egerton University",
                code: "01252"
            },
            {
                branch: "Eldama Ravine",
                code: "01163"
            },
            {
                branch: "Eldoret",
                code: "01109"
            },
            {
                branch: "Eldoret Advantage Branch",
                code: "01336"
            },
            {
                branch: "Eldoret East",
                code: "01312"
            },
            {
                branch: "Eldoret West",
                code: "01168"
            },
            {
                branch: "Emali",
                code: "01215"
            },
            {
                branch: "Embu",
                code: "01116"
            },
            {
                branch: "Flamingo",
                code: "01217"
            },
            {
                branch: "Garden City",
                code: "01325"
            },
            {
                branch: "Garden Plaza",
                code: "01290"
            },
            {
                branch: "Garissa",
                code: "01169"
            },
            {
                branch: "Garsen",
                code: "01226"
            },
            {
                branch: "Gateway Msa Road",
                code: "01204"
            },
            {
                branch: "Gatundu",
                code: "01214"
            },
            {
                branch: "Gigiri Square",
                code: "01323"
            },
            {
                branch: "Gikomba",
                code: "01257"
            },
            {
                branch: "Gilgil",
                code: "01159"
            },
            {
                branch: "Githunguri",
                code: "01193"
            },
            {
                branch: "Haile Selassie",
                code: "01288"
            },
            {
                branch: "Head Office",
                code: "01094"
            },
            {
                branch: "Head Office Finance",
                code: "01096"
            },
            {
                branch: "Hola",
                code: "01146"
            },
            {
                branch: "Homa Bay",
                code: "01230"
            },
            {
                branch: "Hurlingham",
                code: "01306"
            },
            {
                branch: "ICD Kibarani",
                code: "01321"
            },
            {
                branch: "Industrial Area Nairobi",
                code: "01113"
            },
            {
                branch: "Isibania",
                code: "01284"
            },
            {
                branch: "Isiolo",
                code: "01216"
            },
            {
                branch: "Iten",
                code: "01158"
            },
            {
                branch: "JKIA",
                code: "01326"
            },
            {
                branch: "JKUAT",
                code: "01314"
            },
            {
                branch: "Jogoo Rd Nairobi",
                code: "01137"
            },
            {
                branch: "Kabarnet",
                code: "01152"
            },
            {
                branch: "Kabartonjo",
                code: "01311"
            },
            {
                branch: "Kajiado",
                code: "01131"
            },
            {
                branch: "Kakamega",
                code: "01110"
            },
            {
                branch: "Kakuma Branch",
                code: "01333"
            },
            {
                branch: "Kangare",
                code: "01207"
            },
            {
                branch: "Kangema",
                code: "01117"
            },
            {
                branch: "Kapenguria",
                code: "01151"
            },
            {
                branch: "Kapsabet",
                code: "01166"
            },
            {
                branch: "Kapsowar",
                code: "01231"
            },
            {
                branch: "Karatina",
                code: "01120"
            },
            {
                branch: "Karen",
                code: "01202"
            },
            {
                branch: "Kariobangi",
                code: "01260"
            },
            {
                branch: "Kasarani Branch",
                code: "01309"
            },
            {
                branch: "Kawangare",
                code: "01254"
            },
            {
                branch: "Kayole",
                code: "01324"
            },
            {
                branch: "KCB CPC",
                code: "01092"
            },
            {
                branch: "KCB Custody services",
                code: "01133"
            },
            {
                branch: "Kehancha",
                code: "01232"
            },
            {
                branch: "Kengeleni",
                code: "01225"
            },
            {
                branch: "Kericho",
                code: "01106"
            },
            {
                branch: "Kericho East",
                code: "01328"
            },
            {
                branch: "Keroka",
                code: "01233"
            },
            {
                branch: "Kerugoya",
                code: "01195"
            },
            {
                branch: "Kiambu",
                code: "01119"
            },
            {
                branch: "Kianyaga",
                code: "01208"
            },
            {
                branch: "Kibera",
                code: "01307"
            },
            {
                branch: "Kibwezi",
                code: "01164"
            },
            {
                branch: "KICC",
                code: "01104"
            },
            {
                branch: "Kikim",
                code: "01313"
            },
            {
                branch: "Kikuyu",
                code: "01129"
            },
            {
                branch: "Kilgoris",
                code: "01234"
            },
            {
                branch: "Kilifi",
                code: "01174"
            },
            {
                branch: "Kilimani Platinum",
                code: "01337"
            },
            {
                branch: "Kilindini Mombasa",
                code: "01111"
            },
            {
                branch: "Kimathi",
                code: "01255"
            },
            {
                branch: "Kimilili",
                code: "01235"
            },
            {
                branch: "Kipande House",
                code: "01101"
            },
            {
                branch: "Kiriaini",
                code: "01251"
            },
            {
                branch: "Kisauni",
                code: "01287"
            },
            {
                branch: "Kiserian",
                code: "01285"
            },
            {
                branch: "Kisii",
                code: "01191"
            },
            {
                branch: "Kisii West",
                code: "01303"
            },
            {
                branch: "Kisumu",
                code: "01105"
            },
            {
                branch: "Kisumu Airport",
                code: "01297"
            },
            {
                branch: "Kisumu West",
                code: "01246"
            },
            {
                branch: "Kitale",
                code: "01149"
            },
            {
                branch: "Kitale Advantage",
                code: "01319"
            },
            {
                branch: "Kitengela",
                code: "01162"
            },
            {
                branch: "Kitui",
                code: "01135"
            },
            {
                branch: "Kwale",
                code: "01258"
            },
            {
                branch: "Kyuso",
                code: "01267"
            },
            {
                branch: "Lamu",
                code: "01173"
            },
            {
                branch: "Langata",
                code: "01338"
            },
            {
                branch: "Lavington",
                code: "01320"
            },
            {
                branch: "Limuru",
                code: "01155"
            },
            {
                branch: "Litein",
                code: "01236"
            },
            {
                branch: "Lodwar",
                code: "01144"
            },
            {
                branch: "Loitokitok",
                code: "01142"
            },
            {
                branch: "Lokichogio",
                code: "01203"
            },
            {
                branch: "Londiani Branch",
                code: "01237"
            },
            {
                branch: "Luanda",
                code: "01238"
            },
            {
                branch: "Maasai Mara",
                code: "01310"
            },
            {
                branch: "Machakos",
                code: "01194"
            },
            {
                branch: "Makindu",
                code: "01278"
            },
            {
                branch: "Makongeni",
                code: "01316"
            },
            {
                branch: "Makutano",
                code: "01327"
            },
            {
                branch: "Makuyu",
                code: "01211"
            },
            {
                branch: "Malaba",
                code: "01239"
            },
            {
                branch: "Malindi",
                code: "01199"
            },
            {
                branch: "Mandera",
                code: "01150"
            },
            {
                branch: "Maralal",
                code: "01154"
            },
            {
                branch: "Mariakani",
                code: "01220"
            },
            {
                branch: "Marigat",
                code: "01247"
            },
            {
                branch: "Marsabit",
                code: "01140"
            },
            {
                branch: "Mashariki",
                code: "01249"
            },
            {
                branch: "Masii",
                code: "01270"
            },
            {
                branch: "Matuu",
                code: "01134"
            },
            {
                branch: "Maua",
                code: "01253"
            },
            {
                branch: "Mbale",
                code: "01183"
            },
            {
                branch: "Mbita",
                code: "01304"
            },
            {
                branch: "Menengai Crater",
                code: "01271"
            },
            {
                branch: "Meru",
                code: "01123"
            },
            {
                branch: "Migori",
                code: "01192"
            },
            {
                branch: "Milimani",
                code: "01175"
            },
            {
                branch: "Mogotio",
                code: "01331"
            },
            {
                branch: "Moi Avenue Nairobi",
                code: "01100"
            },
            {
                branch: "Moi International Airport",
                code: "01299"
            },
            {
                branch: "Moi Referral Hospital",
                code: "01318"
            },
            {
                branch: "Mois Bridge",
                code: "01248"
            },
            {
                branch: "Mombasa High Court",
                code: "01296"
            },
            {
                branch: "Moyale",
                code: "01127"
            },
            {
                branch: "Mpeketoni",
                code: "01221"
            },
            {
                branch: "Mtitu Andei",
                code: "01222"
            },
            {
                branch: "Mtwapa",
                code: "01223"
            },
            {
                branch: "Muhoroni",
                code: "01240"
            },
            {
                branch: "Mukuruweini",
                code: "01177"
            },
            {
                branch: "Mumias",
                code: "01124"
            },
            {
                branch: "Muranga",
                code: "01115"
            },
            {
                branch: "Mutomo",
                code: "01219"
            },
            {
                branch: "Mvita",
                code: "01136"
            },
            {
                branch: "Mwea",
                code: "01212"
            },
            {
                branch: "Mwembe Tayari",
                code: "01286"
            },
            {
                branch: "Mwingi",
                code: "01148"
            },
            {
                branch: "Nairobi High Court",
                code: "01295"
            },
            {
                branch: "Naivasha",
                code: "01190"
            },
            {
                branch: "Nakuru",
                code: "01103"
            },
            {
                branch: "Namanga",
                code: "01256"
            },
            {
                branch: "Nandi Hills",
                code: "01143"
            },
            {
                branch: "Nanyuki",
                code: "01125"
            },
            {
                branch: "Naro Moro",
                code: "01250"
            },
            {
                branch: "Narok",
                code: "01184"
            },
            {
                branch: "NextGen",
                code: "01330"
            },
            {
                branch: "Ngara",
                code: "01266"
            },
            {
                branch: "Njambini",
                code: "01213"
            },
            {
                branch: "Njoro",
                code: "01218"
            },
            {
                branch: "Nkubu",
                code: "01209"
            },
            {
                branch: "Nyahururu",
                code: "01122"
            },
            {
                branch: "Nyali",
                code: "01300"
            },
            {
                branch: "Nyamira",
                code: "01176"
            },
            {
                branch: "Nyeri",
                code: "01112"
            },
            {
                branch: "Ol Kalou",
                code: "01210"
            },
            {
                branch: "Ongata Rongai",
                code: "01161"
            },
            {
                branch: "Othaya",
                code: "01185"
            },
            {
                branch: "Oyugis",
                code: "01241"
            },
            {
                branch: "Parklands",
                code: "01334"
            },
            {
                branch: "Port Victoria",
                code: "01298"
            },
            {
                branch: "Prestige Plaza",
                code: "01259"
            },
            {
                branch: "River Road",
                code: "01114"
            },
            {
                branch: "Riverside Advantage",
                code: "01322"
            },
            {
                branch: "Rongo",
                code: "01283"
            },
            {
                branch: "Ruiru",
                code: "01147"
            },
            {
                branch: "Salama House Mortgage Centre",
                code: "01289"
            },
            {
                branch: "Sarit Centre",
                code: "01141"
            },
            {
                branch: "Sarit Centre Mortgage Centre",
                code: "01291"
            },
            {
                branch: "Serem",
                code: "01244"
            },
            {
                branch: "Siaya",
                code: "01121"
            },
            {
                branch: "Sondu",
                code: "01245"
            },
            {
                branch: "Sori",
                code: "01305"
            },
            {
                branch: "Sotik",
                code: "01189"
            },
            {
                branch: "Syokimau",
                code: "01317"
            },
            {
                branch: "Tala",
                code: "01130"
            },
            {
                branch: "Taveta",
                code: "01224"
            },
            {
                branch: "Thika",
                code: "01108"
            },
            {
                branch: "Thika Road Mall Branch",
                code: "01308"
            },
            {
                branch: "Tom Mboya",
                code: "01107"
            },
            {
                branch: "Town Centre",
                code: "01272"
            },
            {
                branch: "Trade Services",
                code: "01293"
            },
            {
                branch: "Treasury Sq Mombasa",
                code: "01102"
            },
            {
                branch: "Two Rivers",
                code: "01329"
            },
            {
                branch: "Ugunja",
                code: "01242"
            },
            {
                branch: "Ukunda",
                code: "01157"
            },
            {
                branch: "UN Gigiri",
                code: "01145"
            },
            {
                branch: "United Mall",
                code: "01243"
            },
            {
                branch: "University Way",
                code: "01167"
            },
            {
                branch: "Upperhill Platinum",
                code: "01332"
            },
            {
                branch: "Utawala",
                code: "01339"
            },
            {
                branch: "Village Market",
                code: "01180"
            },
            {
                branch: "Voi",
                code: "01186"
            },
            {
                branch: "Wajir",
                code: "01153"
            },
            {
                branch: "Watamu",
                code: "01227"
            },
            {
                branch: "Webuye",
                code: "01188"
            },
            {
                branch: "Westgate Advantage",
                code: "01301"
            },
            {
                branch: "Wote",
                code: "01095"
            },
            {
                branch: "Wundanyi",
                code: "01198"
            }
        ]
    },
    {
        bank: "Kenya Women Microfinance Bank",
        code: ""
    },
    {
        bank: "Mayfair CIB Bank",
        code: ""
    },
    {
        bank: "Middle East Bank",
        code: ""
    },
    {
        bank: "National Bank",
        code: ""
    },
    {
        bank: "NCBA Bank",
        code: ""
    },
    {
        bank: "Paramount Bank",
        code: ""
    },
    {
        bank: "Prime Bank",
        code: [
            {
                branch: "Head Office Riverside",
                code: "000"
            },
            {
                branch: "Kenindia",
                code: "001"
            },
            {
                branch: "Biashara",
                code: "002"
            },
            {
                branch: "Mombasa",
                code: "003"
            },
            {
                branch: "Westlands",
                code: "004"
            },
            {
                branch: "Industrial Area",
                code: "005"
            },
            {
                branch: "Kisumu",
                code: "006"
            },
            {
                branch: "Parklands",
                code: "007"
            },
            {
                branch: "Riverside Drive",
                code: "008"
            },
            {
                branch: "Card Centre",
                code: "009"
            },
            {
                branch: "Hurlingham",
                code: "010"
            },
            {
                branch: "Capital Centre",
                code: "011"
            },
            {
                branch: "Nyali",
                code: "012"
            },
            {
                branch: "Kamkunji",
                code: "014"
            },
            {
                branch: "Eldoret",
                code: "015"
            },
            {
                branch: "Karen",
                code: "016"
            },
            {
                branch: "Nakuru",
                code: "017"
            },
            {
                branch: "Garden City",
                code: "020"
            },
            {
                branch: "Two Rivers",
                code: "021"
            },
            {
                branch: "UAP Towers",
                code: "022"
            },
            {
                branch: "Meru",
                code: "023"
            },
            {
                branch: "Lavington",
                code: "024"
            },
            {
                branch: "Village Market",
                code: "025"
            }
        ]
    },
    {
        bank: "Sidian Bank",
        code: ""
    },
    {
        bank: "Spire Bank",
        code: ""
    },
    {
        bank: "Stanbic Bank",
        code: ""
    },
    {
        bank: "Standard Chartered Bank (K) Ltd",
        code: ""
    },
    {
        bank: "Victoria Commercial Bank",
        code: []
    }
]